var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('a-card',{staticClass:"container"},[_c('a-form',{attrs:{"label-col":{ span: 6 },"wrapper-col":{ span: 14 },"colon":false,"form":_vm.form}},[_c('a-row',[_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"议题编号"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'number',
                            {
                                initialValue: _vm.id,
                                rules: [
                                    { required: true, message: '请输入！' },
                                ],
                            },
                        ]),expression:"[\n                            'number',\n                            {\n                                initialValue: id,\n                                rules: [\n                                    { required: true, message: '请输入！' },\n                                ],\n                            },\n                        ]"}],staticStyle:{"width":"200px"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"上报部门"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                                        'deptId',
                                        { initialValue: _vm.detail.deptId,rules: [{ required: true, message: '请选择！' }] },
                                    ]),expression:"[\n                                        'deptId',\n                                        { initialValue: detail.deptId,rules: [{ required: true, message: '请选择！' }] },\n                                    ]"}],staticStyle:{"width":"200px"},attrs:{"placeholder":"","show-search":"","filter-option":_vm.$selectFilterOption}},_vm._l((_vm.organizationList),function(item){return _c('a-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(_vm._s(item.uniqueName))])}),1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"部门领导"}},[_c('EmployeeSelector',{staticStyle:{"width":"200px"},attrs:{"value":_vm.selectedCharger.name ? [_vm.selectedCharger] : []},on:{"change":(arr) => {
                                if (arr.length > 0) {
                                _vm.selectedCharger = arr[0];
                                } else {
                                _vm.selectedCharger = {};
                                }
                            }}},[_c('a-button',{staticStyle:{"text-align":"left"},attrs:{"block":""}},[(_vm.selectedCharger.name)?_c('div',[_vm._v(" "+_vm._s(_vm.selectedCharger.name)+" ")]):_c('div',[_vm._v(" ")])])],1)],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"议题名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'name',
                            {
                                initialValue: _vm.detail.name,
                                rules: [
                                    { required: true, message: '请输入！' },
                                ],
                            },
                        ]),expression:"[\n                            'name',\n                            {\n                                initialValue: detail.name,\n                                rules: [\n                                    { required: true, message: '请输入！' },\n                                ],\n                            },\n                        ]"}],staticStyle:{"width":"200px"}})],1)],1),_c('a-col',{attrs:{"lg":12,"md":12,"sm":24}},[_c('a-form-item',{attrs:{"label":"议题扼要"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
                            'brief',
                            {
                                initialValue: _vm.detail.brief,
                                rules: [
                                    { required: true, message: '请输入！' },
                                ],
                            },
                        ]),expression:"[\n                            'brief',\n                            {\n                                initialValue: detail.brief,\n                                rules: [\n                                    { required: true, message: '请输入！' },\n                                ],\n                            },\n                        ]"}],staticStyle:{"width":"200px"}})],1)],1)],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")]),_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleSubmit}},[_vm._v("保存")]),_c('a-button',{attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.process}},[_vm._v("保存并发布")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }